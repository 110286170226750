.booking_container {
  color: red;
  margin: 20px;
  background-color: rgb(50, 50, 50);
  padding: 20px;
  border: solid rgba(0, 0, 0, 0.301);
  border-width: 1px 2px 4px 2px;
  border-radius: 20px;
  padding-top: 30px;
}
.booking_header {
  font-family: Helvetica;
  font-weight: bold;
  color: red;
  margin: 50px;
}
.booking_titles h4 {
  font-family: Helvetica;
  font-weight: bold;
  color: red;
  margin: 10px;
  text-align: center;
}
.booking_divider {
  color: white;
  margin: 0px;
  padding: 0px;
}
.booking_text {
  color: white;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
.empty_container {
  width: 100%;
  height: 200px;
  margin: 0px;
  padding: 0px;
}
.booking_jumbotron {
  background-color: red;
  color: rgb(33, 33, 33);
}
.featurette_divider {
  margin: 1rem 0; /* Space out the Bootstrap <hr> more */
}
.table_img {
  border-radius: 40px;
}
.booking_imgSize {
  display: flex;
  margin: auto;
  overflow: hidden;
  border-radius: 55px;
}
html,
body,
#app,
#app > div {
  height: 100%;
  width: 100%;
  background-color: rgb(33, 33, 33);
}
.b_example_divider {
  height: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
}
.hero {
  background-color: rgb(33, 33, 33);
  color: white;
}
.hero_text {
  background-color: rgb(33, 33, 33);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.button {
  color: white;
  background-color: red;
  border-color: red;
  margin-bottom: 25px;
}
@media (min-width: 0) and (max-width: 576px) {
  .booking_imgSize {
    overflow: hidden;
    border-radius: 25px;
  }
  .booking_img {
    width: 100%;
    height: 100%;

    /*clip-path: inset(100px 0px 0px 0px);*/
  }
  .booking_titles h4 {
    font-size: small;
    margin: 0px;
    text-align: center;
    color: red;
    font-weight: 400;
  }
  .booking_text {
    color: white;
    margin: auto;
    margin-top: 0px;
    margin-bottom:0px;
    text-align: center;
    font-size: small;
  }
  .hero h1 {
    font-size: xx-large;
    margin: auto;
  }
  .hero p {
    font-size: medium;
  }
  .booking_header {
    font-family: Helvetica;
    font-weight: bold;
    margin: 50px;
  }
  
  .booking_jumbotron {
    margin-bottom: 10px;
  }
}
