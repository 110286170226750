.footer{
    background-color: whitesmoke;
    font-family: Helvetica;
    color: rgb(33, 33, 33);
    width: 100%;    
    position: absolute;
    padding-left: 55px;
    padding-right: 55px; 
     
}
.footer_style{
    border-top: 3px solid rgb(33, 33, 33); 
    border-width: 1px;
}

.tooTop{
    align-items: flex-end;
}
.Nav_logo{
    height: 60px;
    width: 60px;
    border-radius: 10px;
}