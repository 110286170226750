  
  .body {    
    color: whitesmoke;
    background-color: rgb(33, 33, 33);   
  }
  .contain{
    width: 100%;
  }
  
  .fields{
    color:whitesmoke; 
    justify-content: center; 
    margin-left: 100px;   
    margin-right: 100px;
  }
  p{
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 50px;
  }
  h2{
    color: red;
  }  
  
  .form_signUp {
    height: 40px;
    padding: 20px;
    margin-bottom: 10px;
  }
  
  .form_signUp .form-floating:focus-within {
    z-index: 2;
  }
  
  .form_signUp input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: rgb(33, 33, 33);
    margin-left: 0;
  }
  
  .form_signUp input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: rgb(33, 33, 33);
    margin-left: 0;
  }
  .logo{
    border-radius: 10px;
    margin: 50px;
  }

  .Sbutton{
    text-align: center;    
  }

  .signButton{
    background-color: red;
    color: black;    
    border-color:rgba(33, 33, 33, 0.8);
    margin-top: 35px;
    margin-bottom: 155px;
    margin-left: 20px;
    padding-left: 50px;
    padding-right: 50px;
  }
  
.signButton:hover{
    background-color: black;
    color: red;
    border-color:rgba(33, 33, 33, 0.8);
}
.signButton:active:hover{
    background-color: black;
    color: red;
    border-color:rgba(33, 33, 33, 0.8);
}
@media (max-width: 992px) {
  .fields{
    color:whitesmoke; 
    justify-content: center; 
    margin: 40px;
    
  }
  .Sbutton{
    text-align: end;    
  }
  .signButton{
    background-color: red;
    color: black;    
    border-color:rgba(33, 33, 33, 0.8);
    margin-top: 30px;
    margin-bottom: 55px;
    margin-left: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .contain{
    width: 100%;
    margin: auto;
  }
}
@media (min-width: 0px) and (max-width: 576px){
  
}