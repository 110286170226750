.booking_date {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 40px;
    height: 100px;
    width: 120px;    
    background-color: red;
    color: black;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 3px 3px 1px rgba(75, 75, 75, 0.4);
  }
  
  .booking_date__month {
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .booking_date__year {
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .booking_date__day {
    font-size: 1.5rem;
    font-weight: bold;
  }
  @media (min-width: 0) and (max-width: 992px) {
    .booking_date {
      display: flex;
      flex-direction: column;
      height: 80px;
      width: 60px;      
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;       
      margin-left: 20px;   
    }    
    .booking_date__month {
      font-size: 0.8rem;
      font-weight: bold;
    }
    
    .booking_date__year {
      font-size: 0.8rem;
      font-weight: bold;
    }
    
    .booking_date__day {
      font-size: 1.0rem;
      font-weight: bold;
    }
  }
  @media (min-width: 0) and (max-width: 576px) {
    .booking_date {
      display: flex;
      flex-direction: column;
      height: 60px;
      width: 50px;      
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;       
      margin-left: -15px;   
    }
    .booking_date__month {
      font-size: 0.6rem;
      font-weight: bold;
    }
    
    .booking_date__year {
      font-size: 0.6rem;
      font-weight: bold;
    }
    
    .booking_date__day {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }