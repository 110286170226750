.bookings {
  padding: 1rem;
  background-color: rgb(50, 50, 50);
  margin: 2rem auto;
  width: 100%;
  border-radius: 20px;
}
.paragraph{
  font-size: larger;
  margin: 50px;
  font-family: Helvetica;
  font-weight: bold;
}


.headings {
  margin: 50px;
  color: red;
  text-align: center;
  font-size: xx-large;
}

.bookings_chart h4 {
  font-size: xx-large;
  margin-bottom: 0.5rem;
  color: red;
  text-align: center;
}
.booking_titles {
  font-family: Helvetica;
  font-weight: bold;
  color: white;
  margin: 15px;
  text-align: center;
  padding-top: 10px;
}
.booking_divider {
  color: white;
  margin: 0px;
  padding: 0px;
}

@media (min-width: 0) and (max-width: 992px) {
  .headings {
    font-size: x-large;
  }
  .bookings_chart h4 {
    font-size: x-large;
  }
  .booking_titles,
  h4 {
    font-family: Helvetica;
    color: white;
    margin: auto;
    justify-content: center;
    align-items: center;
    padding: auto;
    text-align: center;
    font-size: 18px;
    width: 100%;
  }
}
@media (min-width: 0px) and (max-width: 576px) {
  .headings {
    font-size: x-large;
  }
  .bookings_chart h4 {
    font-size: large;
  }
  .booking_titles,
  h4 {
    font-family: Helvetica;
    color: white;
    margin: auto;
    justify-content: center;
    align-items: center;
    padding: auto;
    text-align: center;
    font-size: 11px;
    width: 100%;
  }
}
