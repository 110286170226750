.booking_item {
  display: flex;
  justify-content: space-around;
  padding: auto;
  margin: 10px;
  background-color: whitesmoke;
  border-radius: 10px;
  border-color: rgb(75, 75, 75);
  box-shadow: 3px 3px 3px rgba(75, 75, 75, 0.3);
}
.booking_item__description {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  margin: auto;
  padding: auto;
}
.booking_item h2 {
  font-size: 20px;
  margin-left: 15px;
  color: #3a3a3a;
  text-align: center;
}
.booking_icon {
  fill: rgb(75, 75, 75);
  margin: auto;
  margin-right: 20px;
}
.booking_icon:hover {
  fill: red;
}
.booking_CMicon {
  width: 60px !important;
  height: 25px !important;
  margin: -20px;
  padding: 0px;
}
.booking_CMicon:hover {
  border: 2px solid red;
}
.booking_CMicon:checked {
  background-color: rgb(75, 75, 75);
  border: 2px solid red;
  width: 60px !important;
  height: 28px !important;
}
.CMContainer { 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
}
@media (min-width: 0) and (max-width: 992px) {
  .booking_item {
    background-color: whitesmoke;
    border-radius: 10px;
    width: 100%;
    height: 100px;
    margin: 10px;
    align-items: center;
    justify-content: center;
  }
  .booking_item__description {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-flow: row;
    justify-content: space-around;
    margin: auto;
    padding: auto;
  }
  .booking_item h2 {
    font-size: medium;
    margin: -20px;
    margin-left: 0px;
    padding: 0px;
  }
  .CMContainer {
    width: 30px;
    height: 20px;
    padding: 0px;
    margin: 0px;
  }
  .booking_icon {
    fill: rgb(75, 75, 75);
    margin: -20px;
    margin-right: 0px;
    height: 30px;
    width: 30px;
    padding: 0px;
  }
  .booking_CMicon {
    width: 40px !important;
    height: 14px !important;
    margin: -35px;
    padding: 0px;
  }
  .booking_CMicon:checked {    
    width: 40px !important;
    height: 14px !important;
  }
}
@media (min-width: 0) and (max-width: 576px) {
  .booking_item {
    background-color: whitesmoke;
    border-radius: 10px;
    width: 100%;
    height: 100px;
    margin: 10px;
    align-items: center;
    justify-content: space-evenly;
  }
  .booking_item__description {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-flow: row;
    justify-content: space-around;
    margin: auto;
    padding: auto;
  }
  .booking_item h2 {
    font-size: x-small;
    margin: 0px;
    margin-left: -25px;
    padding: 0px;
  }
  .CMContainer {
    width: 40px;
    height: 15px;
    padding: 0px;
    margin: 15px;
  }
  .booking_icon {
    fill: rgb(75, 75, 75);
    margin: -40px;
    margin-right: 0px;
    height: 30px;
    width: 30px;
    padding: 0px;
  }
  .booking_CMicon {
    width: 40px !important;
    height: 15px !important;
    margin: 5px;
    padding: 0px;
  }
  .booking_CMicon:checked {    
    width: 40px !important;
    height: px !important;
  }
}

