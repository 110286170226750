.icon{
 position: relative;
 margin: 10px;
 margin-bottom: 20px;
}

/* MARKETING CONTENT
  -------------------------------------------------- */
  
  /* Center align the text within the three columns below the carousel */
  .marketing {
    margin-bottom: 1.5rem;
    text-align: center;
    
  }
  /* rtl:begin:ignore */
  .marketing p {
    margin-right: .75rem;
    margin-left: .75rem;
  }
  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }
  /* rtl:end:ignore */

  .button{
    color: white;
    background-color: red;
    border-color: red;
    
  }
  .heading{
    color: red;
    font-weight: bolder; 
  }
  .F{
    font-family: Helvetica;
    font-weight: bold;
    color: rgb(33, 33, 33);
  }
  @media (min-width: 0) and (max-width: 992px){
    .icon{
      width: 100px;
      height: 100px;
            
     }
     .button{
      margin-bottom: 20px;
     }
     .icon_container{
      border: solid rgba(0, 0, 0, 0.301);
      border-radius: 10px;
      border-width: 0px 2px 4px 2px;
      align-items: center;
      padding-top: 10px;
      margin-top: 20px;
      
     }
     .marketing {
      text-align: center;
      margin: 10px;
      padding: 10px;
    }
    
    .marketing p {
      margin-right: 30px;
      margin-left: 30px;
    }
  }
  @media (min-width: 992px) {
    .icon{
      width: 120px;
      height: 120px;
            
     }
     .icon_container{
      border: solid rgba(0, 0, 0, 0.301);
      border-radius: 10px;
      border-width: 0px 2px 4px 2px;
      align-items: center;
      padding-top: 10px;
      margin-top: 20px;
      
     }
  }