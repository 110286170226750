/* Featurettes
  ------------------------- */
  
  .featurette_divider {
    margin: 2rem 0; /* Space out the Bootstrap <hr> more */
  }
  
  /* Thin out the marketing headings */
  /* rtl:begin:remove */
  .featurette_heading {
    letter-spacing: -.05rem;
  }
  .img{
    width: 500px;
    height: 500px;
  }
  @media (min-width: 0) and (max-width: 992px) {
    .img{
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .featurette_heading {
      letter-spacing: -.05rem;
      padding-left: 20px;
      padding-right: 20px;
    }
    .text{
      font-size: medium;
      margin: auto;
      margin-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .featurette_container{
      margin: auto;
     }
  }
@media (min-width: 992px){
  .img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .featurette_heading {
    letter-spacing: -.05rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .text{  
    margin: auto;  
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;    
  }
  .featurette_container{
   margin: auto;
  }
}
@media (min-width: 0px) and (max-width: 576px){
  .img{
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .featurette_heading {
      letter-spacing: -.05rem;
      padding-left: 20px;
      padding-right: 20px;
    }
    .text{
      font-size: medium;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .featurette_container{
      margin: auto;
     }
  
}
  