.bookings_filter {
  color: black;
  padding: 0 1rem;
  text-align: center;
}
.booking_days {
  text-align: center;   
  margin: 0px;
  padding: auto;
}

.bookings_filter__control {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  align-content: center;
}

.bookings_filter h4 {
  font-size: xx-large;
  margin-bottom: 0.5rem;
  color: red;
}

.bookings_filter select {
  font: inherit;
  padding: 0.5rem 3rem;
  font-weight: bold;
  border-radius: 6px;
}
.filterDay {
  background-color: rgb(255, 255, 255);
  color: black;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  font-size: large;
  border-width: 1px;
  border-right: 1px;
  align-content: center;
  border-radius: 15px;
  margin: 1px;
}
.filterDay h4 {
  color: red;
}

.filterDay:hover {
  background-color: black;
  color: red;
  font-weight: bold;
}

.filterDay_active {
  background-color: black;
  color: red;
  font-weight: bold;
}


@media (min-width: 0) and (max-width: 992px) {
  .filterDay {
    background-color: white;
    color: black;
    padding: 26.3px;
    text-align: center;
    font-weight: 500;
    font-size: smaller;
    border-width: 1px;
    border-right: 1px;    
    margin: 1px;
    
    
  }
  .filterDay:hover {
    background-color: black;
    color: red;
    font-weight: bold;
  }
  
  .filterDay_active {
    background-color: black;
    color: red;
    font-weight: bold;
  }
  .bookings_filter h4 {
    font-size: x-large;    
  }
  .booking_days {
     margin: auto;  
     padding: auto;
    
  }
}
