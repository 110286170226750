  
  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    color: whitesmoke;
    background-color: rgb(33, 33, 33); 
    height: 100%;      
  }
  
  .fields{
    color: rgb(33, 33, 33);     
  }  
  
  .form_signin {
    max-width: 330px;
    padding: 15px;
  }
  
  .form_signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form_signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: rgb(33, 33, 33);
    margin-left: 0;
  }
  
  .form_signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: rgb(33, 33, 33);
    margin-left: 0;
  }
  .logo{
    border-radius: 10px;
  }

  .signButton{
    background-color: red;
    color: black;    
    border-color:rgba(33, 33, 33, 0.8);
    margin-top: 35px;
  }
  
.signButton:hover{
    background-color: black;
    color: red;
    border-color:rgba(33, 33, 33, 0.8);
}
.signButton:active:hover{
    background-color: black;
    color: red;
    border-color:rgba(33, 33, 33, 0.8);
}
.close{
  background-color: white;
  margin-bottom: 60px;
}

.closeLoc{
 
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: end;
 
}
