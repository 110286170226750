.bookings_page{
  color: whitesmoke;
  background-color: rgb(33, 33, 33);
}
.booking_jumbotron{
    
    background-color: red;
    color: rgb(33, 33, 33);    
  }
  html, body, #app, #app>div {
    height: 100%;
    width: 100%;
    background-color:rgb(33, 33, 33);
  }
  .hero{
    background-color: rgb(33, 33, 33);
    color: white;
    
  }
  .hero_text{
    background-color: rgb(33, 33, 33);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  .table_img{     
    border-radius: 40px;
    margin-top: 20px; 
    margin-bottom: 20px;      
  }
  .b_example_divider {
    height: 1rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
    margin-bottom: 50px;
  }
  @media (min-width: 0) and (max-width: 576px) {
    .hero, h1{
      font-size: x-large;   
      margin: 10px;   
    }
    .hero, p{
      font-size: medium;
      margin: 10px;      
    }
    .table_img{ 
      margin-top: 30px;    
      margin-bottom: 30px;       
    }
  }
  