.bookings_list {
    list-style: none;
    padding: 0;
  }
  
  .bookings_list__fallback {
    color: red;
    text-align: center;
    margin: 50px;
  }

  @media (min-width: 0) and (max-width: 992px) {
    .bookings_list {
      height: auto;
    }
  }