.container {
  background-color: rgba(245, 245, 245, 0.749);
  width: 60%;
  margin: 250px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 1px 8px rgba(255, 0, 0, 0.8);
}
.container_img {
    background-color: rgba(245, 245, 245, 0.749);
    width: 60%;
    margin: 250px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 1px 8px rgba(255, 0, 0, 0.8);
  }

.title {
  font-size: x-large;
  color: red;
  font-family: Helvetica;
  margin-left: 10px;
}
.heading {
  color: rgb(33, 33, 33);
}

input {
  border-radius: 5px;
  width: 70%;
  height: 30px;
  margin-left: 20px;
  padding: 20px;
  border-width: 0px;
  margin-bottom: 10px;
}

.addUserButton {
  background-color: red;
  width: 130px;
  height: 50px;
  text-align: center;
  color: rgb(33, 33, 33);
  box-shadow: 0 1px 5px rgba(232, 80, 80, 0.8);
  border-color: rgb(33, 33, 33, 0.8);
  border-radius: 10px;
  border-width: 0px;
}
.addUserButton:hover {
  background-color: black;
  color: red;
}

.newUser__action {
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
}

.error {
  visibility: hidden;
}
.img {
  width: 500px;
  height: 500px;
}
.booking_img {
  width: 100%;
  height: 100%;
  padding: 0%;

}
