.log_in {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: red;
  color: black;
  box-shadow: 3px 3px 3px rgba(33, 33, 33, 0.6);
  text-decoration: none;
  border-radius: 10px;
  margin-right: 10px;
  justify-items: end;
  font-weight: 500;
}
.log_in:link {
  text-decoration: none;
}
.log_in:hover {
  background-color: black;
  color: red;
  text-decoration: none;
}
.sign_up {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: black;
  color: red;
  box-shadow: 3px 3px 3px rgba(33, 33, 33, 0.6);
  text-decoration: none;
  border-radius: 10px;
  font-weight: 500;
}
.sign_up:link {
  text-decoration: none;
}
.sign_up:hover {
  background-color: red;
  color: black;
  text-decoration: none;
}
.dropdown_toggle {
  outline: 0;
  margin-left: 20px;
}

.Navbar_titles {
  font-family: Helvetica;
  color: rgb(33, 33, 33);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.Navbar_titlesL {
  font-family: Helvetica;
  color: rgb(33, 33, 33);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.Navbar_titlesM {
  font-family: Helvetica;
  color: rgb(33, 33, 33);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.Nav_button1 {
  margin-left: auto;
}
.Nav_button2 {
  
}
.Nav_logo {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  box-shadow: 3px 3px 3px rgba(33, 33, 33, 0.3);
  margin-right: 20px;
}
.profile{
  fill: rgb(75, 75, 75);   
  margin-left: auto;
  background-color: rgb(248, 249, 250);
  border-color: rgb(248, 249, 250);
}
.profile:hover{
  background-color: rgb(248, 249, 250);
  border-color: rgb(248, 249, 250);
  fill: red;
}
.profile:focus {
  fill: rgb(75, 75, 75);
  background-color: rgb(248, 249, 250);
  border-color: rgb(248, 249, 250);
}
.profile:hover:active {
  fill: rgb(75, 75, 75);
  background-color: rgb(248, 249, 250);
  border-color: rgb(248, 249, 250);
}

@media (min-width: 0) and (max-width: 992px) {
  .Nav_logo {
    height: 60px;
    width: 60px;
  }
  .profile{    
    fill: rgb(75, 75, 75);   
    margin: auto;
    display: flex;
  }
  .log_in {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 155px;
    padding-right: 155px;
    text-align: center;
    margin-right: 0;
    box-shadow: 1px 4px 4px rgba(33, 33, 33, 0.4);
    font-weight: 500;
  }
  .sign_up {
    text-align: center;
    padding-left: 150px;
    padding-right: 150px;
    margin-bottom: 15px;
    box-shadow: 1px 4px 4px rgba(33, 33, 33, 0.4);
    font-weight: 500;
    
  }
  .Navbar_titles {  
    margin: auto;  
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 154px;
    padding-right: 154px;
    text-align: center;
    margin-right: 0;
    border-radius: 8px;       
    box-shadow: 1px 4px 4px rgba(75, 75, 75, 0.4);
    background-color:  rgb(75, 75, 75);
    color: white;
    font-size: large;
    
  }
  .Navbar_titlesL{         
    padding-left: 135px;
    padding-right: 135px;
    text-align: center;
    margin-right: 0;
    border-radius: 8px;
    margin-bottom: 10px;       
    box-shadow: 1px 4px 4px rgba(75, 75, 75, 0.4);
    background-color:  rgb(75, 75, 75);
    color: white;
    font-size: large;
  }
  .Navbar_titlesM{         
    padding-left: 142px;
    padding-right: 142px;
    text-align: center;
    margin-right: 0;
    border-radius: 8px;
    margin-bottom: 10px;       
    box-shadow: 1px 4px 4px rgba(75, 75, 75, 0.4);
    background-color:  rgb(75, 75, 75);
    color: white;
    font-size: large;
  }
  .Nav_brand {
    font-size: larger;
  }
  .Navbar_titles:active {
    font-family: Helvetica;
    color: red;
    background-color: black;
  }
  .Nav_button1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }
  .Nav_button2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .log_in:active:hover {
    background-color: red;
    color: black;
    text-decoration: none;
  }
}
@media (min-width: 0px) and (max-width: 576px){
  .Navbar_titles {  
    justify-content: center;
    padding-left: 140px;
    padding-right: 140px;
    
  }
  .Navbar_titlesL{         
    padding-left: 121px;
    padding-right: 121px;
    
  }
  .Navbar_titlesM{         
    padding-left: 128px;
    padding-right: 128px;
    
  }
  .log_in {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 140px;
    padding-right: 140px;
  }
  .sign_up {
    text-align: center;
    padding-left: 135px;
    padding-right: 135px;
    
  }
}
