.new_booking__controls{
  margin: auto;
  padding: auto;
}

.new_booking__control,label{
  font-weight: 600;            
  font-size: larger;
  margin: auto;
  margin-bottom: 10px;
  width: 70%;
}

.new_booking__control,input{
  font: inherit;
      padding: auto;
      margin: 1px;
      border-radius: 6px;
      border: 1px solid #ccc;
      width: 30%;
      text-align: center;
      font-size: medium;
      margin-bottom: 10px;
}

.new_booking__actions {
  text-align: center;
  margin-top: 20px;
  font-size: medium;
}
  @media (min-width: 0px) and (max-width: 996px){
    .new_booking__controls{
      margin: auto;
      padding: auto;
    }
    
    .new_booking__control,label{
      font-weight: 600;            
      font-size: medium;
      margin: auto;
      margin-bottom: 0px;
      width: 50%;
    }
    
    .new_booking__control,input{
      font: inherit;
      padding: auto;
      margin: auto;
      border-radius: 6px;
      border: 1px solid #ccc;
      width: 50%;
      text-align: center;
      font-size: small;
      margin-bottom: 10px;
    }
    
    .new_booking__actions {
      text-align: center;
      margin-top: 20px;
      font-size: medium;
    }
  }
  @media (min-width: 0px) and (max-width: 576px){
    .new_booking__controls{
      margin: auto;
      padding: auto;
    }
    
    .new_booking__control,label{
      font-weight: 600;            
      font-size: small;
      margin: auto;
      margin-bottom: 0px;
      width: 50%;
    }
    
    .new_booking__control,input{
      font: inherit;
      padding: auto;
      margin: auto;
      border-radius: 6px;
      border: 1px solid #ccc;
      width: 50%;
      text-align: center;
      font-size: smaller;
      margin-bottom: 10px;
    }
    
    .new_booking__actions {
      text-align: center;
      margin-top: 20px;
      font-size: x-small;
    }
  }