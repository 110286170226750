.chart_bar {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;    
  }
  
  .chart_bar__inner {
    height: 100%;
    width: 30px;     
    box-shadow: 0px 0px 1px 1px rgba(241, 28, 28, 0.8);   
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 12px;
  }
  
  .chart_bar__fill {
    background-color: red;
    width: 100%;
    transition: all 0.3s ease-out;
  }
  
  .chart_bar__label {
    font-weight: bold;
    font-size: medium;
    text-align: center;
    margin-bottom: 10px;
  }

  .progress{
    text-align: center;    
    padding: 4px;
  }
  .progress ,h5{
    text-align: center;
    margin: 10px;
  }
  .progress_bar{
    padding: 9.5px;
  }

  @media (min-width: 0) and (max-width: 992px) {
    
    .progress{
      text-align: center;     
      width: 50%;
      margin: 0px;
      
    }
    .progress h5{
      text-align: center;      
      width: 100%;
    }
    .progress_bar{
      padding: 9.5px;
      margin-right: 10px;
      
    }
  }