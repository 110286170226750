/* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */
@media (min-width: 992px) {
  .rounded-lg-3 {
    border-radius: 0.3rem;
  }
}
@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
/* Carousel base class */
.carousel {
  margin-bottom: 2rem;
  border-radius: 55px;
  overflow: hidden;
}
/* Since positioning the image, we need to help out the caption */
.carousel_caption1 {
  bottom: 2%;
  z-index: 10;
  top: 3%;  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
}
.carousel_caption2 {  
  z-index: 10;
  top: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.carousel_caption3 {
  z-index: 10;
  top: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center !important;  
}

/* Declare heights because of positioning of img element */
.carousel_item {
  height: 750px;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.b_example_divider {
  height: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
a.hover:hover {
  background-color: whitesmoke;
  color: rgb(33, 33, 33);
}
.button {
  color: white;
  background-color: red;
  border-color: red;
}
.J {
  color: red;
  font-weight: bolder;
}
.F {
  font-family: Helvetica;
  font-weight: bold;
  color: rgb(33, 33, 33);
}
.hero_title{
  color: red;
}

@media (min-width: 0) and (max-width: 992px) {
  
  .carousel_caption1 {
    bottom: 3%;    
    top: 1%;  
    left: 10%;
    right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: white;
    margin: 0px;
    padding: auto;
    text-align: center;
  }
  
  .carousel_caption1 ,p{
    font-size: 15px;
    max-width: 100%;
    padding: auto;
  }
  .carousel_caption2 {  
    bottom: 5%;    
    top: 1%;  
    left: 10%;
    right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: white;
    margin: 0px;
    padding: auto;
    text-align: center;
  }
  .carousel_caption3 {
    bottom: 5%;    
    top: 1%;  
    left: 10%;
    right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: white;    
    padding: auto;
    text-align: center; 
  }
  .carousel_item {
    height: 650px;
    font-size: medium;
    
  }
  .carousel {
    margin-bottom: 1px;
    border-radius: 15px;
    overflow: hidden;
    border: solid rgba(0, 0, 0, 0.301);
    border-width: 1px 2px 4px 2px;
    height: 650px;
  }
  .button:active:hover {
    color: red;
    background-color: black;
    border-color: black;
  }
  .button {
    padding: 5px;
    font-size: medium;
  }
  .hero_text{
    font-size: large;
    text-align: center;
  }
  .hero_title{
    font-size: 42px;
  }
}
@media (min-width: 992px) and (max-width: 1200px){
  .carousel {
    margin-bottom: 1px;
    border-radius: 15px;
    overflow: hidden;
    border: solid rgba(0, 0, 0, 0.301);
    border-width: 1px 2px 4px 2px;
  }
}
@media (min-width: 0px) and (max-width: 576px){
  .hero_text{
    font-size: large;
    text-align: center;
    margin: auto;
  }
  .hero_title{
    font-size: 40px;
    
  }
  .carousel_caption1 {
    
  }
  
  .carousel_caption1 p{
    font-size: 1;
    max-width: 100%;
    padding: 5px;
    text-align: center;
    margin: 8px;
  }
  .carousel_caption2 p{
    font-size: 1;
    max-width: 100%;
    padding: 5px;
    text-align: center;
    margin: 8px;
  }
  .carousel_caption3 p{
    font-size: 1;
    max-width: 100%;
    padding: 5px;
    text-align: center;
    margin: 8px;
  }
  .carousel_caption2 {  
    bottom: 5%;    
    top: 1%;  
    left: 10%;
    right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: white;
    margin: 0px;
    padding: auto;
    text-align: center;
  }
  .carousel_caption3 {
    bottom: 5%;    
    top: 1%;  
    left: 10%;
    right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: white;    
    padding: auto;
    text-align: center; 
  }
  .carousel_item {
    height: 625px;
    font-size: medium;
    
  }
  .carousel {
    margin-bottom: 1px;
    border-radius: 15px;
    overflow: hidden;
    border: solid rgba(0, 0, 0, 0.301);
    border-width: 1px 2px 4px 2px;
    height: 650px;
  }
  .button:active:hover {
    color: red;
    background-color: black;
    border-color: black;
  }
  .button {
    padding: 5px;
    font-size: small;
  }
}
