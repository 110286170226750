.new_booking {
    background-color: red;
    padding: 1rem;
    margin: 2rem auto;
    width: 100%;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  }
  
  .new_booking button {
    font: inherit;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 1px solid red;
    background-color: black;
    color: white;
    border-radius: 12px;
    margin:20px;
  }
  
  .new_booking button:hover,
  .new_booking button:active {
    background-color: black;
    border-color: red;
  }
  
  .new_booking button.alternative {
    color: red;
    border-color: transparent;
    background-color: transparent;
  }
  
  .new_booking button.alternative:hover,
  .new_booking button.alternative:active {
    background-color: black;
  }
  @media (min-width: 0) and (max-width: 992px) {
    .new_booking button {
      font: inherit;
      cursor: pointer;
      padding: 1rem 2rem;
      border: 1px solid red;
      background-color: black;
      color: white;
      border-radius: 12px;
      margin:10px;
    }
  }
